import Backbone from 'backbone';

// Pretty big hack, apparently protects us from zombies!
// https://lostechies.com/derickbailey/2011/09/15/zombies-run-managing-page-transitions-in-backbone-apps/
Backbone.View.prototype.close = function () {
  this.remove();
  this.unbind();
  if (this.onClose) {
    this.onClose();
  }
};

const ContentView = Backbone.View.extend({
  initialize(options) {
    this.name = options.content.name;
    this.app = options.app;
    this.content = options.content;
    this.parent = options.parent;
    this.viewTemplate = options.viewTemplate;
    this.editTemplate = options.editTemplate;
    this.previewTemplate = options.previewTemplate;
    this.mode = 'view';
    this.shouldPreview = false;
    this.changed = false;
    this.newValue = null;
    this.additionalData = null;
    this.newItem = false;
    this.removed = false;
    this.pageCallbacks = null;
    if (options.pageCallbacks) {
      this.pageCallbacks = options.pageCallbacks;
    }
  },
  setEditMode(edit) {
    if (edit) {
      this.mode = 'edit';
    } else if (this.shouldPreview) {
      this.mode = 'preview';
    } else {
      this.mode = 'view';
    }
  },
  getContentPath() {
    if (this.newItem) {
      let id;
      if (this.parent === null) throw new Error("No parent view found, can't generate a new contentPath");
      else if (this.parent.typename === 'ListContentView') id = this.parent.getChildIndex(this);
      else if (this.parent.typename === 'ObjectContentView') id = this.content.parent.getChildModelAttributeName(this.content);
      else throw new Error('Unknown parent content type.');
      return this.content.getContentPath().replace(/\/[^/]+\/$/, `/${id}/`);
    }
    return this.content.getContentPath();
  },
  getDOMId() {
    return this.getContentPath().replace(/[^A-Za-z0-9/-]/g, '').replace(/\//g, '_').replace(/^_/, '');
  },
  getChangedViews() {
    const changedViews = [];
    if (this.changed) {
      changedViews.push(this);
    }
    return changedViews;
  },
  change(newValue) {
    this.changed = true;
    this.newValue = newValue;
    this.removed = false;
  },
  remove() {
    this.changed = true;
    this.newValue = null;
    this.removed = true;
  },
  clear() {
    this.changed = false;
    this.newValue = null;
    this.removed = false;
    this.additionalData = null;
  },
  renderPreview() {
    this.$el.html(this.previewTemplate({ id: this.getDOMId(), value: this.newValue || this.content.value, content: this.content }));
  },
  renderView() {
    this.$el.html(this.viewTemplate({ id: this.getDOMId(), value: this.newValue || this.content.value, content: this.content }));
  },
  renderEdit() {
    this.$el.html(this.editTemplate({ id: this.getDOMId(), value: this.newValue || this.content.value, content: this.content }));
  },
  render() {
    if (this.mode === 'edit') {
      if (this.shouldPreview) {
        this.renderPreview();
      } else {
        this.renderEdit();
      }
    } else if (this.mode === 'view') {
      this.renderView();
    } else if (this.mode === 'preview') {
      if (!this.previewTemplate) {
        this.renderPreview();
      } else {
        this.renderView();
      }
    } else {
      throw new Error(`Invalid content view mode '${this.mode}' must be 'edit', 'view' or 'preview'.`);
    }

    if (this.postRender) {
      setTimeout(() => {
        this.postRender(); // bit of a hack
      }, 0);
    }

    this.$el.removeClass('selectable');
    if (this.pageCallbacks && this.pageCallbacks.shouldAllowSelection && this.pageCallbacks.shouldAllowSelection(this)) { 
      this.$el.addClass('selectable'); 
    }

    // this.el = this.$el.children()[0];
    this.delegateEvents(this.events);
    return this;
  },
  postRender() {
    // override me
  },
  validate() {
    return this.content.validate();
  },
  getChanges() {
    return {
      type: this.name,
      path: this.getContentPath(),
      old: this.content.value,
      new: this.removed ? null : this.newValue,
      removed: this.removed,
      additional: this.additionalData,
    };
  },
  save() {
    if (this.changed) {
      if (this.removed) {
        this.content.save(null);
      } else {
        this.content.save(this.newValue);
      }
    } else {
      throw new Error('Nothing to save.');
    }
    return Promise.resolve();
  },
});

// Another hack, this time for properly extending events
// https://danhough.com/blog/backbone-view-inheritance/
ContentView.extend = function (child) {
  const view = Backbone.View.extend.apply(this, arguments);
  view.prototype.events = Object.assign({}, this.prototype.events, child.events);
  return view;
};

export default ContentView;
