import Backbone from 'backbone';
import DataModelView from 'views/datamodel';
import HeaderView from 'views/header';
import SideBarView from 'views/sidebar';
import FooterView from 'views/footer';
import 'bootstrap';
import 'admin-lte';

import 'css/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'ionicons/dist/css/ionicons.min.css';
import 'admin-lte/dist/css/AdminLTE.min.css';
import 'css/skin-idealayer-light.css';

const AppView = Backbone.View.extend({

  initialize(options) {
    this.clientData = options.clientData;
    this.projectId = options.projectId;
    this.dataModel = options.dataModel;
    this.content = options.content;
    /*if (options.skin) {
      $(document.body).removeClass('skin-idealayer');
    } else {
      $(document.body).addClass('skin-idealayer');
    }*/
    $(document.body).addClass('skin-idealayer');
    this.headerView = new HeaderView({ logoOnly: options.skin.logoOnly, logo: options.skin.logo, logoPadding: options.skin.logoPadding, logoText: options.skin.logoText, logoSecondaryText: options.skin.logoSecondaryText, colour1: options.skin.colour1, colour2: options.skin.colour2 });
    this.sideBarView = new SideBarView({ projectId: this.projectId, projects: this.clientData.projects });
    // this.footerView = new FooterView({});
  },

  render() {
    this.headerView.render();
    this.sideBarView.render();
    // this.footerView.render();
    // return this;
  },

});

export default AppView;
