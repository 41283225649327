import $ from 'jquery';
import viewTemplate from 'templates/content/url_view.handlebars';
import editTemplate from 'templates/content/url_edit.handlebars';
import ContentView from '../content';

// Backbone view
const UrlContentView = ContentView.extend({
  events: {
    'change input': 'onTextChange',
  },
  initialize(options) {
    ContentView.prototype.initialize.call(this, {
      app: options.app, content: options.content, parent: options.parent, viewTemplate, editTemplate, previewTemplate: null, pageCallbacks: options.pageCallbacks
    });
  },
  onTextChange(e) {
    this.change($(e.currentTarget).val());
  },
});
ContentView.extend(UrlContentView);

export default {
  name: 'url',
  view: UrlContentView,
};
