import { getSkinInfo } from './skin';

const loginHtml = `<style type="text/css">
.login-logo {
  margin-bottom: 0px;
  background-color: #263238;
  color: #fff;
  font-size: 28px;
  height: 48px;
}
.login-logo .title {
  margin-left: 10px;
  position: relative;
  top: 2px;
  font-weight: 600;
}
.login-logo .subtitle {
  margin-left: 5px;
  position: relative;
  top: 2px;
}
.modal-header {
  background-color: #263238;
}
.modal-title {
  color: #fff;
}
.modal-header .close {
  color: #fff;
}

/* Disable number spinners on desktop browsers */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
<div class="login-box">
<div class="login-logo"></div>
<div class="login-box-body">
  <p class="login-box-msg">Please enter your username and password.</p>
  <div id="messages"></div>
  <form id="login-form" style="margin-bottom: 20px">
    <div
      class="form-group"
      style="width: 145px; margin-left: auto; margin-right: auto"
    >
      <input
        type="text"
        name="username"
        class="form-control"
        placeholder="Username"
      />
      <input
        type="password"
        name="password"
        class="form-control"
        placeholder="Password"
      />
      <!--<span class="glyphicon glyphicon-lock form-control-feedback"></span>-->
    </div>
    <div
      class="form-group"
      style="width: 145px; margin-left: auto; margin-right: auto"
    >
      <button
        type="submit"
        value="Log In"
        class="btn btn-primary btn-block btn-flat"
        style="width: 145px; margin-left: auto; margin-right: auto"
      >
        Sign In
      </button>
    </div>
  </form>
</div>
</div>`;

// eslint-disable-next-line import/prefer-default-export
export async function mountSpaLoginPage() {
  const wrapper = document.querySelector('.wrapper');

  document.body.classList.add('login-page');
  wrapper.innerHTML = loginHtml;

  const loginLogo = document.querySelector('.login-logo');
  
  const skinInfo = await getSkinInfo();
  let padding = '10px';
  if (skinInfo.logoPadding) padding = skinInfo.logoPadding;
  if (skinInfo.logoOnly) {
    loginLogo.innerHTML = `<img height='33px' src='${skinInfo.logo}' style='vertical-align: baseline; height: 48px; object-fit: contain; padding: ${padding}'></img>`;
  } else {
    loginLogo.innerHTML = `<img height='33px' src='${skinInfo.logo}' style='vertical-align: middle; height: 48px; object-fit: contain; padding: ${padding}'></img><span class='title'>${skinInfo.logoText}</span><span class='subtitle'>${skinInfo.logoSecondaryText}</span>`;
  }
  loginLogo.style = `background-color: ${skinInfo.colour1}`;
  
  const loginForm = document.querySelector('#login-form');

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(loginForm);
    const data = Object.fromEntries(formData.entries());
    const response = await fetch('login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      redirect: 'error',
    });
    const value = await response.json();
    const messagesDiv = document.querySelector('#messages');
    if (value.status === 'error') {
      const p = document.createElement('p');
      p.className = 'login-box-msg';
      p.textContent = value.message;
      p.style = 'color: red;';
      messagesDiv.replaceChildren(p);
    } else {
      messagesDiv.replaceChildren();
      window.location.href = '/';
    }
  };

  loginForm.addEventListener('submit', onSubmit);

  // unmount
  return () => {
    // TODO: properly implement unmount
    loginForm.removeEventListener('submit', onSubmit);
  };
};
