import Evaporate from 'evaporate';
import uuid from 'uuid';

export default class S3UploadHandler {
  constructor(uploadDataStoreInfo, projectId) {
    this.evaporate = new Evaporate({
      signerUrl: `api/projects/${projectId}${uploadDataStoreInfo.signURL}`,
      aws_key: uploadDataStoreInfo.key,
      bucket: uploadDataStoreInfo.bucket,
      aws_url: uploadDataStoreInfo.url,
      logging: false,
    });
    this.projectId = projectId;
  }

  getDownloadURL(contentSetFilePath){
    return `api/projects/${this.projectId}/download/${contentSetFilePath}`;
  }

  doUpload(file, onComplete, onProgress) {
    const fileUUID = uuid(); // Temporary uuid that is only used to prevent clashes in the upload bucket
    const path = `projects/${this.projectId}/uploads/${fileUUID}_${file.name}`;
    let isComplete = false;
    let currentUpload = this.evaporate.add({
      name: path,
      file,
      complete(xhr, awsObjectKey) {
        isComplete = true;
        currentUpload = null;
        onComplete(fileUUID);
      },
      progress(progress) {
        if (!isComplete) onProgress(progress);
      },
    });
    return () => {
      this.evaporate.cancel(currentUpload);
    }
  }
}


/* S3.prototype.addMultiFileDialog = function(id){
  var $input = $("#"+id);
  var $text = $input.next('label').find("span");
  var originalText = $text.text();

  $input.change(e => {
    var files = e.target.files;

    //Show filename or number of files
    var fileName = "";
    if(files && files.length>1){
      fileName = "" + files.length + " files selected";
    } else if(e.target.value){
      fileName = e.target.value.split("\\").pop();
    }
    if(fileName){
      $text.text(fileName);
    } else {
      $text.text(originalText);
    }

    //Upload
    for(const file of files){
      this.evaporate.add({
          name: uuid(),
          file: file,
          complete: function(){
              console.log("Complete!");
          },
          progress: function(progress){
              console.log('Uploading: ' + (progress*100).toFixed(2) + "%...");
          }
        });
    };
  });
}; */