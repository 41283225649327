import tinyMCE from 'tinymce';

import 'tinymce/themes/modern/theme';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/skins/lightgray/skin.min.css';
import 'tinymce/plugins/code';
import 'tinymce/plugins/lists';
import viewTemplate from 'templates/content/html_view.handlebars';
import editTemplate from 'templates/content/html_edit.handlebars';
import ContentView from '../content';

// Backbone view
const HTMLContentView = ContentView.extend({
  // editorId: null,
  events: { },
  initialize(options) {
    ContentView.prototype.initialize.call(this, {
      app: options.app, content: options.content, parent: options.parent, viewTemplate, editTemplate, previewTemplate: null, pageCallbacks: options.pageCallbacks
    });
  },
  postRender() {
    if (this.mode === 'edit') {
      if (this.editorId) tinyMCE.remove("#"+this.editorId);
      const editorRef = this.$el.children('textarea')[0];
      editorRef.id = "tinymce-tmp"+Math.random();
      tinyMCE.init({
        target: editorRef,
        skin: false,
        plugins: ['code', 'lists'],
        setup: (editor) => {
          editor.on('Change', (e) => {
            this.change(editor.getContent());
          });
        },
        statusbar: false,
        branding: false,
        menubar: false,
        toolbar: 'undo redo | styleselect | bold italic | bullist numlist | code',
      });
    }
  },
});
ContentView.extend(HTMLContentView);

export default {
  name: 'html',
  view: HTMLContentView,
};
