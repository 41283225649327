import Backbone from 'backbone';
import is from 'is';
import { makeContentType, toHuman } from '@cruiserinteractive/cruiser-cms-content';
import dataModelViewTemplate from 'templates/datamodel-view.handlebars';
import dataModelEditTemplate from 'templates/datamodel-edit.handlebars';
import contentTypeView from 'templates/datamodel-contenttype-view.handlebars';

const DataModelView = Backbone.View.extend({
  viewTemplate: dataModelViewTemplate,
  editTemplate: dataModelEditTemplate,
  events: {
    'click .editbutton': 'edit',
  },
  initialize(options) {
    this.classLoader = options.classLoader;
    this.app = options.app;
    this.dataModel = options.dataModel;
    this.project = options.project;
    this.editMode = false;

    const mainContentType = makeContentType(this.classLoader, '__datamodelroot__', { type: 'model', model: 'main' });
    mainContentType.onDataModelReady(this.dataModel);

    const modelContentClass = this.classLoader.getContent(mainContentType.name);
    const viewClass = this.classLoader.getView('model');

    this.dataModel.main.id = '__datamodel_main_id';
    const mainContent = new modelContentClass(this.classLoader, mainContentType, this.dataModel.main, null);

    this.mainView = new viewClass({ app: this.app, content: mainContent, parent: null });
    // this.modelViews = new viewClass({ app: this.app, content: this.currentContent, parent: null });

    if (this.mainView.loadChildViews) {
      this.mainView.loadChildViews();
      for (const obj of this.mainView.childViews) {
        var view = obj.view;
        if (view.loadChildViews && view.typename == 'ListContentView') {
          view.loadChildViews();
        }/* else if(view.typename == "ObjectContentView"){
          view.loadChildViews();
          for(const obj2 of view.childViews){
            var view2 = obj2.view;
            if(view2.loadChildViews && (view2.typename == "ListContentView")){
              view2.loadChildViews();
            }
          }
        } */
      }
    }

    this.mainView.editMode = this.editMode;
    for (const obj of this.mainView.childViews) {
      var view = obj.view;
      view.editMode = this.editMode;
      if (view.typename == 'ListContentView') {
        for (const obj2 of view.childViews) {
          const view2 = obj2.view;
          view2.editMode = this.editMode;
        }
      }
    }
  },
  edit() {
    this.editMode = true;
    this.render();
  },
  getMain() {
    let result = '';
    if (is.object(this.dataModel) && is.object(this.dataModel.main)) {
      const main = this.dataModel.main;
      if (this.editMode) {
        for (const value of Object.values(main.config)) {
          result += value.edit();
        }
      } else {
        for (const value of Object.values(main.config)) {
          result += value.view();
        }
      }
    }
    return result;
  },
  getModel(model) {
    let result = '';
    if (this.editMode) {
      for (const value of Object.values(model.childrenByKey)) {
        result += value.edit();
      }
    } else {
      for (const value of Object.values(model.childrenByKey)) {
        result += value.view();
      }
    }
    return result;
  },
  getModels() {
    const models = [];
    if (is.object(this.dataModel)) {
      for (const [key, value] of Object.entries(this.dataModel)) {
        if (key != 'main' && is.object(value)) {
          models.push({ key: toHuman(key), value: this.getModel(value) });
        }
      }
    }
    return models;
  },
  render() {
    if (this.editMode) {
      this.$el.html(this.editTemplate({ projectId: this.project.id, projectName: this.project.name }));
    } else {
      this.$el.html(this.viewTemplate({ projectId: this.project.id, projectName: this.project.name }));
    }

    this.$el
      .find('.placemainhere')
      .removeClass('placemainhere')
      .html(this.getMain()/* this.mainView.render() */);

    this.$el
      .find('.placemodelshere')
      .removeClass('placemodelshere')
      .html(contentTypeView({ models: this.getModels() }));

    setTimeout(() => {
      // this.postRender();
    }, 0);

    return this;
  },
  postRender() {
    console.log(this.$el.find('.box-body'));
    this.$el.find('.box-body')
      .tree({
        data: [
          {
            label: 'node1',
            id: 1,
            children: [
              { label: 'child1', id: 2 },
              { label: 'child2', id: 3 },
            ],
          },
          {
            label: 'node2',
            id: 4,
            children: [
              { label: 'child3', id: 5 },
            ],
          },
        ],
        autoOpen: true,
      });
  },
});

export default DataModelView;

/* define(['backbone', 'utils', 'is'], function(Backbone, utils, is) {


  var DefaultContentTypeView = function(key, value){
    return TextView(key, value);
  };

  var ListContentTypeView = function(key, value){
    return "list";
  };

  var getView = function(key, value){
    var type = value;
    if(is.object(value)){
      type = value.type;
    }
    if(type==="list"){
      return ListContentTypeView(key, value.type, value);
    }else{
      return DefaultContentTypeView(key, value.type, value);
    }
  };

  var DataModelView = Backbone.View.extend({
    template: _.template("<h3>Data Model</h3><%= stuff %>"),
    initialize: function(options) {
      this.dataModel = options.model;
    },
    getFields: function(){
      var result = "";
      for(const [key, value] of Object.entries(this.dataModel.main)){
        result += getView(key, value);
      }
      return result;
    },
    render: function(){
      this.$el.html(this.template({stuff: this.getFields()}));
      return this;
    }
  });

  return DataModelView;
}); */

/*
var ContentTypeWithArgsView = function(key, type, args){
    var children = "";
    for(const [key, value] of Object.entries(args)){
      children += ContentTypeView(key, value);
    }
    return _.template("<div class='left'><%=name%></div><div class='right'><%=type%></div><div class='contenttypebody'><%=children%></div>")({ name: utils.toHuman(key), type: type, children: children});
  };
  if(is.object(value)){
          if(Object.keys(value).length==1 && is.string(value.type)){
            result += ContentTypeView(key, value.type);
          }else{
            //Duplicate and remove type key
            var args = JSON.parse(JSON.stringify(value));
            delete args.type;
            result += ContentTypeWithArgsView(key, value.type, args);
          }
        }else{
          result += ContentTypeView(key, value);
        }
        */
