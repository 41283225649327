var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "<a href target=\"_blank\">"
    + container.escapeExpression(__default(require("../../helpers/lastElementOfURLMinusUUID.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.value : depth0),{"name":"lastElementOfURLMinusUUID","hash":{},"data":data,"loc":{"start":{"line":2,"column":39},"end":{"line":2,"column":74}}}))
    + "</a>";
},"3":function(container,depth0,helpers,partials,data) {
    return "No File";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"form-control-static adjustpadding\">\n  "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.value : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":2,"column":100}}})) != null ? stack1 : "")
    + "\n</div>\n";
},"useData":true});