var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("../../helpers/lastElementOfURLMinusUUID.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.value : depth0),{"name":"lastElementOfURLMinusUUID","hash":{},"data":data,"loc":{"start":{"line":3,"column":392},"end":{"line":3,"column":427}}}));
},"3":function(container,depth0,helpers,partials,data) {
    return "Choose a file&hellip;";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "<div class=\"row\"><div class=\"col-xs-12\" style=\"padding-top: 2px; padding-bottom: 2px;\">\n<input type=\"file\" name=\""
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + "\" id=\""
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + "\" class=\"inputfile inputfile-1\" />\n<label for=\""
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + "\"><svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"17\" viewBox=\"0 0 20 17\"><path d=\"M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z\" /></svg> <span>"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.value : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":379},"end":{"line":3,"column":463}}})) != null ? stack1 : "")
    + "</span></label>\n</div></div>\n";
},"useData":true});