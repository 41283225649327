import $ from 'jquery';
import Backbone from 'backbone';
import indexTemplate from 'templates/index.handlebars';

const IndexView = Backbone.View.extend({
  el: $('.content-wrapper'),
  template: indexTemplate,
  initialize(options) {

  },
  render() {
    this.$el.html(this.template({}));
    return this;
  },
});

export default IndexView;
