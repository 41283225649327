import Backbone from 'backbone';

const Router = Backbone.Router.extend({

  routes: {
    '': 'default',
    'projects/:projectId': 'project',
    'projects/:projectId/content': 'content',
    'projects/:projectId/content/*path': 'content',
    'projects/:projectId/datamodel': 'dataModel',
    'projects/:projectId/datamodel/*path': 'dataModel',
  },

  initialize(options) {
    this.app = options.app;
    this.currentPath = '';
  },

  default() {
    // redirect to default project if the user has access to any
    const { projects } = this.app.clientData;
    if (projects.length > 0) {
      document.location.hash = `#/projects/${projects[0].id}/content`;
    }
  },

  project(projectId) {
    // redirect to content page for this project
    document.location.hash = `#/projects/${projectId}/content`;
  },

  content(projectId, contentPath) {
    if (!contentPath) contentPath = '';
    this.app.changeToContentPage(projectId, contentPath);
  },

  dataModel(projectId, contentPath) {
    if (!contentPath) contentPath = '';
    this.app.changeToDataModelPage(projectId);
  },

});

export default Router;
