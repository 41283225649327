import $ from 'jquery';
import Backbone from 'backbone';
import headerTemplate from 'templates/header.handlebars';

const HeaderView = Backbone.View.extend({
  el: $('.main-header'),
  template: headerTemplate,
  events: {
    'click #logout-button': 'onClickLogout',
  },
  initialize(options) {
    this.logoOnly = options.logoOnly;
    this.logo = options.logo;
    this.logoPadding = options.logoPadding || "10px";
    this.logoText = options.logoText;
    this.logoSecondaryText = options.logoSecondaryText;
    this.colour1 = options.colour1;
    this.colour2 = options.colour2;
  },
  onClickLogout(e) {
    (async () => {
      e.preventDefault();
      const response = await fetch('logout', {
        method: "POST",
        credentials: 'same-origin',
        redirect: 'error',
      });

      if (response.status !== 200) {
        throw new Error(`Looks like there was a problem. Status Code: ${response.status}`);
        // `Server error when fetching JSON from ${path }. Error: ${JSON.stringify(data, null, 2)}`
      }
      window.location = "/";
    })();
  },
  render() {
    this.$el.html(this.template({ logoOnly: this.logoOnly, logo: this.logo, logoPadding: this.logoPadding, logoText: this.logoText, logoSecondaryText: this.logoSecondaryText, colour1: this.colour1, colour2: this.colour2 }));
    return this;
  },
});

export default HeaderView;
