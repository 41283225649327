import $ from 'jquery';
import Backbone from 'backbone';
import contentViewTemplate from 'templates/linktomodalinnercontent.handlebars';

const LinkToModalInnerContentView = Backbone.View.extend({
  viewTemplate: contentViewTemplate,
  events: {
    'click .breadcrumb-link': 'onClickBreadcrumb',
  },
  initialize(options) {
    this.classLoader = options.classLoader;
    this.app = options.app;
    this.content = options.content;
    this.project = options.project;
    this.modelName = options.modelName;
    this.editMode = false;
    this.onSelectItemCallback = options.onSelectItem;
    this.changePath(options.path || ''); // sets currentContent and currentContentView
  },
  changePath(path) {
    this.path = path;
    this.currentContent = this.getSubContent(this.path);
    if (this.currentContentView) this.currentContentView.remove();
    const viewClass = this.classLoader.getView(this.currentContent.name);
    this.currentContentView = new viewClass({
      app: this.app,
      content: this.currentContent,
      parent: null,
      pageCallbacks: {
        shouldAllowSelection: (view) => {
          return (view.content.contentType.name === 'object' && view.content.contentType.model.name === this.modelName);
        },
        onSelect: view => {
          const uuid = view.content.uuid;
          const contentPath = view.content.getContentPath();
          const displayName = view.content.displayName;
          this.onSelectItemCallback(uuid, displayName, contentPath);
        },
        onNavigate: path => {
          this.changePath(path);
          this.render();
        }
      },
    });
    this.currentContentView.loadChildViews();
    this.currentContentView.setEditMode(this.editMode);
  },
  getSubContent(path) {
    if (path !== '') {
      const elements = path.split('/');
      let current = this.content;
      for (const val of elements) {
        if (val === '') continue;
        if (current.getChild) {
          const child = current.getChild(val);
          if (child) current = child;
          else throw new Error(`Specified content path was not found: ${path}.\r\n Failed at: ${val}`);
        } else {
          throw new Error(`Specified content path was not found: ${path}.\r\n Failed at: ${val}`);
        }
      }
      return current;
    }
    return this.content;
  },
  onClickBreadcrumb(e) {
    e.preventDefault();
    // var projectId = $(e.currentTarget).attr("href").replace(/^#\/?projects\//, "").replace(/\/content\/?.*$/, "");
    let contentPath = $(e.currentTarget).attr('href').replace(/^#\/?projects\/[^/]+\/content\/?/, '');
    if (!contentPath) contentPath = '';
    this.changePath(contentPath);
    this.render();
  },
  getBreadcrumbs() {
    const breadcrumbs = [{ name: '<i class="fa fa-home"></i>&nbsp;&nbsp;Home', path: '', projectId: this.project.id }];
    const parentChain = this.currentContent.getParentChain();
    parentChain.shift();
    for (const content of parentChain) {
      breadcrumbs.push({ name: content.getDisplayName(), path: content.getContentPath(), projectId: this.project.id });
    }
    return breadcrumbs;
  },
  render() {
    this.$el.html(this.viewTemplate({ breadcrumbs: this.getBreadcrumbs(), projectId: this.project.id, projectName: this.project.name }));
    this.$el
      .find('.placecontenthere')
      .removeClass('placecontenthere')
      .html(this.currentContentView.render().el);
    return this;
  },
});

export default LinkToModalInnerContentView;
