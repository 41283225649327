import $ from 'jquery';
import viewTemplate from 'templates/content/boolean_view.handlebars';
import editTemplate from 'templates/content/boolean_edit.handlebars';
import ContentView from '../content';

// Backbone view
const BooleanContentView = ContentView.extend({
  events: {
    'change input': 'onChange',
  },
  initialize(options) {
    ContentView.prototype.initialize.call(this, {
      app: options.app, content: options.content, parent: options.parent, viewTemplate, editTemplate, previewTemplate: null, pageCallbacks: options.pageCallbacks
    });
  },
  onChange(e) {
    this.change(!!$(e.currentTarget).prop('checked'));
  },
});
ContentView.extend(BooleanContentView);

export default {
  name: 'boolean',
  view: BooleanContentView,
};
