import $ from 'jquery';
import Backbone from 'backbone';
import footerTemplate from 'templates/footer.handlebars';

const FooterView = Backbone.View.extend({
  el: $('.main-footer'),
  template: footerTemplate,
  initialize(options) {

  },
  render() {
    this.$el.html(this.template({ stuff: 'lol' }));
    return this;
  },
});

export default FooterView;
