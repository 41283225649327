import $ from 'jquery';
import viewTemplate from 'templates/content/dollars_view.handlebars';
import editTemplate from 'templates/content/dollars_edit.handlebars';
import ContentView from '../content';

// Backbone view
const DollarsContentView = ContentView.extend({
  events: {
    'change input': 'onTextChange',
  },
  initialize(options) {
    ContentView.prototype.initialize.call(this, {
      app: options.app, content: options.content, parent: options.parent, viewTemplate, editTemplate, previewTemplate: null, pageCallbacks: options.pageCallbacks
    });
  },
  onTextChange(e) {
    this.change($(e.currentTarget).val());
  },
});
ContentView.extend(DollarsContentView);

export default {
  name: 'dollars',
  view: DollarsContentView,
};
