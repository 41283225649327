var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<a class=\"breadcrumb-link\" href=\"#/projects/"
    + alias4(((helper = (helper = helpers.projectId || (depth0 != null ? depth0.projectId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"projectId","hash":{},"data":data,"loc":{"start":{"line":2,"column":113},"end":{"line":2,"column":126}}}) : helper)))
    + "/content"
    + alias4(((helper = (helper = helpers.path || (depth0 != null ? depth0.path : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":2,"column":134},"end":{"line":2,"column":142}}}) : helper)))
    + "\">"
    + ((stack1 = ((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":2,"column":144},"end":{"line":2,"column":154}}}) : helper))) != null ? stack1 : "")
    + "</a>&ensp;|&ensp;";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div style=\"color: #d2d6de;\">\n  <h3 style=\"font-size: 18px; margin-top: 0px;\">"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.breadcrumbs : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":48},"end":{"line":2,"column":180}}})) != null ? stack1 : "")
    + "</h3>\n</div>\n<form class=\"form-horizontal\">\n  <div class=\"box-body placecontenthere\" style=\"padding: 0px;\">\n  </div>\n</form>\n";
},"useData":true});