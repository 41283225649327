import $ from 'jquery';
import 'jquery-sortable';
import ContentView from '../content';

// Backbone view
const ListContentView = ContentView.extend({
  tagName: 'div',
  events: {
    'click .addbutton': 'onClickAddItem',
    'click .deletebutton': 'onClickRemoveItem',
    'click .togglepreview': 'onClickToggleExpanded',
    'click .togglepreview a.toggle': 'onClickToggleExpanded',
    'click .sort': 'onClickSort',
    'click .preview-nav': 'onClickPreviewNav'
  },
  initialize(options) {
    ContentView.prototype.initialize.call(this, {
      app: options.app, content: options.content, parent: options.parent, viewTemplate: '', editTemplate: '', previewTemplate: null, pageCallbacks: options.pageCallbacks
    });
    this.typename = 'ListContentView';
    this.classLoader = this.content.contentClassLoader;
    this.childViews = [];
    this.childViewsLoaded = false;
    this.shouldPreview = !!this.parent;
    this.setEditMode(false);
    this.removals = [];
    this.additionalData = {};
    this.additionalData.removals = [];
    this.gridView = this.content.contentType.gridView;
    this.expanded = !this.parent;// || this.content.children.length <= 4;
  },
  setEditMode(edit) { // propagate down to children if loaded
    ContentView.prototype.setEditMode.call(this, edit);
    if (!this.shouldPreview) {
      for (const { view } of this.childViews) {
        view.setEditMode(edit);
      }
    }
  },
  loadChildViews() {
    if (this.childViews.length > 0) {
      throw new Error('Child views already loaded.');
    }

    if (this.shouldPreview) {
      return;
    }

    /*if (this.childViews.length > 0) {
      for (const obj of this.childViews) {
        obj.view.remove();
      }
      this.childViews = [];
    }*/
    
    for (const item of this.content.children) {
      const viewClass = this.classLoader.getView(item.name);
      const view = new viewClass({ app: this.app, content: item, parent: this, pageCallbacks: this.pageCallbacks });
      this.childViews.push({ view });
    }

    for (const {view} of this.childViews) {
      if (typeof view.loadChildViews !== 'undefined') {
        view.loadChildViews();
      }
    }

    this.childViewsLoaded = true;
  },
  clear() {
    ContentView.prototype.clear.call(this);
    this.removals = [];
    this.additionalData = {};
    this.additionalData.removals = [];
    this.childViews = [];
    this.childViewsLoaded = false;
    this.loadChildViews();
  },
  getChildIndex(child) {
    let childIndex = -1;
    let i = -1;
    this.childViews.find((obj) => {
      ++i;
      const { view } = obj;
      if (view === child) {
        childIndex = i;
        return true;
      }
      return false;
    });
    return childIndex;
  },
  getChangedViews() {
    let changedViews = ContentView.prototype.getChangedViews.call(this);
    for (const {view} of this.childViews) {
      const childViews = view.getChangedViews();
      changedViews = [...changedViews, ...childViews];
    }
    return changedViews;
  },
  addItem() {
    const oldList = this.newValue || this.content.children;
    const contentType = this.content.contentType.ofContentType;
    const newContentClass = this.classLoader.getContent(contentType.name);
    const newContent = new newContentClass(this.classLoader, contentType, contentType.getEmptyContent(oldList.length + 1), this.content);
    const newList = [...oldList, newContent];
    this.change(newList);
    const viewClass = this.classLoader.getView(newContent.name);
    const newView = new viewClass({ app: this.app, content: newContent, parent: this });
    newView.setEditMode(true);
    newView.newItem = true;
    this.childViews.push({ view: newView });
    return newView;
  },
  onClickAddItem(e) {
    e.preventDefault();
    this.addItem();
    this.render();
  },
  onMultiFileUpload(files) {
    const pairs = files.map(file => [this.addItem(), file]);
    this.render(); // to create file input elements
    pairs.forEach(([view, file]) => {
      view.fileUploader.onChooseFile(file);
    });
    this.render(); // to update file names
  },
  onClickRemoveItem(e) {
    e.preventDefault();
    const i = parseInt($(e.currentTarget).data('index'));
    const oldList = this.newValue || this.content.children;
    const view = this.childViews[i].view;
    const contentToRemove = view.content;
    const newList = oldList.filter(x => x !== contentToRemove);
    this.change(newList);
    this.removals.push(contentToRemove);
    if (view.typename === 'ListContentView' && this.content.contentType.ofContentType.typename === 'FileContentType') { this.additionalData.removals.push(contentToRemove); }
    this.render();
  },
  onClickToggleExpanded(e) {
    e.preventDefault();
    e.stopPropagation();
    this.expanded = !this.expanded;
    $(e.currentTarget).closest('.contenttype-list').find('div.togglecontainer').toggleClass('hidden');
    // $(e.currentTarget).closest('.form-group').find('div.togglepreview').toggleClass('hidden');
  },
  onClickPreviewNav(e) {
    e.preventDefault();
    e.stopPropagation();

    if (this.pageCallbacks && this.pageCallbacks.shouldAllowSelection && this.pageCallbacks.shouldAllowSelection(this)) {
      this.pageCallbacks.onSelect(this);
      return;
    }

    if (this.pageCallbacks && this.pageCallbacks.onNavigate) {
      const contentPath = this.content.getContentPath();
      this.pageCallbacks.onNavigate(contentPath);
    }
  },
  onClickSort(e) {
    e.preventDefault();
    const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });
    this.childViews = this.childViews.sort((a, b) => collator.compare(a.view.content.getDisplayName(), b.view.content.getDisplayName()));
    const oldList =  (this.newValue || this.content.children).concat();
    this.change(oldList.sort((a, b) => collator.compare(a.getDisplayName(), b.getDisplayName())));
    this.render();
  },
  isViewRemoved(view) {
    const result = this.removals.find(remove => remove === view.content);
    return result !== undefined;
  },
  renderPreview() {
    this.$el.addClass('contenttype-list');
    this.$el.html(`<div class='form-control-static adjustpadding preview-nav'><a href>${this.content.children.length} items</a></div>`);
  },
  renderView() {
    if (!this.childViewsLoaded) throw new Error('loadChildViews must be called before renderView()');
    this.$el.addClass('contenttype-list');
    if (this.childViews.length === 0) {
      this.$el.html('<div class="form-control-static adjustpadding">None</div>');
      return;
    }

    this.renderInner();
  },
  renderEdit() {
    if (!this.childViewsLoaded) throw new Error('loadChildViews must be called before renderEdit()');
    this.$el.addClass('contenttype-list');
    if (this.childViews.length === 0) {
      this.$el.html('<div style="margin-bottom: 15px;"><div class="form-control-static adjustpadding"><a class="addbutton" href target="_blank">+ Add an Item</a></div></div>');
      return;
    }

    this.renderInner();

    // enable drag and drop
    let startIndex = NaN;
    $(this.$el.find('ol')).sortable({
      onDragStart($item, container, _super, _event) {
        startIndex = $item.index();
        _super($item, container);
      },
      onDrop: ($item, container, _super) => {
        const theList = (this.newValue || this.content.children).concat();
        const newIndex = $item.index();

        const removed = theList.splice(startIndex, 1);
        theList.splice(newIndex, 0, removed[0]);

        const removedView = this.childViews.splice(startIndex, 1);
        this.childViews.splice(newIndex, 0, removedView[0]);
        
        this.change(theList);
        startIndex = NaN;
        _super($item, container);
        this.render();
      },
      distance: 10,
      delay: 100,
    });
  },
  renderInner() {
    const content = document.createDocumentFragment();

    const togglePreview = document.createElement('div');
    togglePreview.className = 'togglepreview form-control-static adjustpadding';
    togglePreview.id = this.getDOMId();

    const link = document.createElement('a');
    link.href = '#';
    link.className = 'toggle';
    const text = document.createTextNode(`${this.content.children.length} item${this.content.children.length === 1 ? '' : 's'}`);

    link.appendChild(text);
    togglePreview.appendChild(link);
    content.appendChild(togglePreview);

    const div = document.createElement('div');
    div.className = `togglecontainer${this.expanded ? '' : ' hidden'}`;
    div.style = 'border: 1px solid #f4f4f4; border-radius: 5px; position: relative;';
    content.appendChild(div);

    if (this.mode === 'edit') {
      const sortButton = document.createElement('a');
      sortButton.href = '#';
      sortButton.className = 'sort';
      sortButton.style = 'position: absolute; top: 0px; right: 0px; background: #eee; padding: 6px; z-index: 100;';
      const text2 = document.createTextNode('sort');
      sortButton.appendChild(text2);
      div.appendChild(sortButton);
    }

    const orderedList = document.createElement('ol');
    orderedList.style = 'overflow: hidden;';
    div.appendChild(orderedList);

    for (const [i, obj] of this.childViews.entries()) {
      if (!this.isViewRemoved(obj.view)) {
        const view = obj.view;
        // var inner = document.createDocumentFragment();
        var $inner;
        if (this.mode==='edit') {
          $inner = $('<li class="editable"></li>');
        } else if (this.gridView) { 
          $inner = $('<li style="margin-bottom: 15px;" class="col-md-4"></li>'); 
        } else { 
          $inner = $('<li style="margin-bottom: 15px;"></li>'); 
        }
        
        $inner.html(view.render().el);

        if (this.mode === 'edit') {
          $inner.append($(`<div class="form-control-static adjustpadding"><a class="deletebutton" href data-index="${i}">[remove]</a></div>`));
        }
        orderedList.appendChild($inner[0]);
      }
    }

    if (this.mode === 'edit') {
      const $addItem = $('<div style="margin-bottom: 15px;"><div class="form-control-static adjustpadding"><a class="addbutton" href target="_blank">+ Add an Item</a></div></div>');
      div.appendChild($addItem[0]);
    }

    this.$el.addClass('contenttype-list');
    this.$el.html(content);
  },
  validate() {
    let result = true;

    // Check my stuff
    if (this.changed) {
      result = this.content.validate();
    }
    if (!result) return false;

    // Check children
    if (this.childViewsLoaded) {
      result = this.childViews.every(obj => obj.view.validate());
    }
    return result;
  },
  save() {
    if (this.changed && this.newValue) {
      this.content.save(this.newValue);
    } else {
      throw new Error('Nothing to save.');
    }
    return Promise.resolve();
  },
});
ContentView.extend(ListContentView);

export default {
  name: 'list',
  view: ListContentView,
};
