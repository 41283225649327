import $ from 'jquery';
import Backbone from 'backbone';
import template from 'templates/linktomodal.handlebars';
import LinkToModalInnerContentView from 'views/linktomodalinnercontent';

const LinkToModalView = Backbone.View.extend({
  template,

  events: {
    'click .cancelbutton': 'onCancel',
    'click .discardbutton': 'onDiscard',
  },

  initialize(options) {
    this.innerContentView = null;
    this.app = options.app;
    this.modelName = options.modelName;
    this.path = options.path;
  },

  show() {
    $('.modal-wrapper').append(this.render().el);
    this.modal = $('#linktomodal');
    this.modal.modal({ backdrop: 'static' });
    /* this.modal.on("hide.bs.modal", function(e){
      reject();
    }); */

    this.innerContentView = new LinkToModalInnerContentView({
      content: this.app.project.content, 
      path: this.path, 
      app: this.app, 
      classLoader: this.app.classLoader, 
      project: this.app.getCurrentProject(), 
      modelName: this.modelName, 
      onSelectItem: (objectId, displayName, contentPath) => { this.onSelectItem(objectId, displayName, contentPath); },
    });
    this.modal.find('.modal-body').html(this.innerContentView.render().el);

    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    }).then((onSelectInfo) => {
      this.modal.modal('hide');
      $('body').removeClass('modal-open');
      $('.modal-backdrop').remove();
      if (this.innerContentView) {
        this.innerContentView.remove();
        this.innerContentView = null;
      }
      return onSelectInfo;
    });
  },

  render() {
    this.$el.html(this.template());
    return this;
  },

  onCancel(e) {
    // e.preventDefault();
    this.resolve(false);
  },

  onSelectItem(objectId, displayName, contentPath) {
    this.resolve({ objectId, displayName, contentPath });
  },

});

export default LinkToModalView;
