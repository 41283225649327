var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<section class=\"content-header\">\n  <h1>\n    View Data Model\n    <!--<small><a class='editbutton' href>[edit]</a></small>-->\n  </h1>\n  <ol class=\"breadcrumb\">\n    <li><a href=\"#\"><i class=\"fa fa-home\"></i> Home</a></li>\n    <li><a href=\"#/projects/"
    + alias4(((helper = (helper = helpers.projectId || (depth0 != null ? depth0.projectId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"projectId","hash":{},"data":data,"loc":{"start":{"line":8,"column":28},"end":{"line":8,"column":41}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.projectName || (depth0 != null ? depth0.projectName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"projectName","hash":{},"data":data,"loc":{"start":{"line":8,"column":43},"end":{"line":8,"column":58}}}) : helper)))
    + "</a></li>\n    <li class=\"active\">Data Model</li>\n  </ol>\n</section>\n<section class=\"content\">\n  <div class=\"row\">\n    <div class=\"col-md-6\">\n      <div class=\"box box-default\">\n        <div class=\"box-header with-border\">\n          <h3 class=\"box-title\">Main</h3>\n        </div>\n        <form class=\"form-horizontal\">\n          <div class=\"box-body placemainhere\">\n          </div>\n          <!--<div class=\"box-footer\">\n            <button type=\"submit\" class=\"btn btn-primary pull-right\">Edit</button>\n          </div>-->\n        </form>\n      </div>\n    </div>\n    <div class=\"col-md-6 placemodelshere\">\n    </div>\n  </div>\n</section>\n";
},"useData":true});