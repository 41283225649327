import $ from 'jquery';
import viewTemplate from 'templates/content/link_view.handlebars';
import editTemplate from 'templates/content/link_edit.handlebars';
import LinkToModalView from 'views/linktomodal';
import ContentView from '../content';

// Backbone view
const LinkContentView = ContentView.extend({
  events: {
    'click button': 'onClickButton',
    'click .preview-nav': 'onClickPreviewNav',
  },
  initialize(options) {
    ContentView.prototype.initialize.call(this, {
      app: options.app, content: options.content, parent: options.parent, viewTemplate, editTemplate, previewTemplate: null, pageCallbacks: options.pageCallbacks
    });    
    this.modelName = this.content.contentType.model.name;
    this.path = this.content.contentType.path;
  },
  renderEdit() {
    const newDisplayName = this.newValue ? this.content.getRoot().findByUUID(this.newValue).displayName : undefined;
    this.$el.html(this.editTemplate({ id: this.getDOMId(), value: newDisplayName || this.content.referenced?.displayName, content: this.content }));
  },
  renderView() {
    const newDisplayName = this.newValue ? this.content.getRoot().findByUUID(this.newValue).displayName : undefined;
    this.$el.html(this.viewTemplate({ id: this.getDOMId(), value: newDisplayName || this.content.referenced?.displayName, content: this.content }));
  },
  onClickButton(_e) {
    const linkToModalView = new LinkToModalView({ app: this.app, modelName: this.modelName, path: this.path });
    linkToModalView.show().then(({ objectId, displayName, contentPath }) => {
      linkToModalView.remove();
      if (objectId) {
        this.change(objectId);
        this.render();
      }
    });
  },
  onClickPreviewNav(e) {
    e.preventDefault();
    e.stopPropagation();

    if (this.pageCallbacks && this.pageCallbacks.shouldAllowSelection && this.pageCallbacks.onSelect && this.pageCallbacks.shouldAllowSelection(this)) { 
      this.pageCallbacks.onSelect(this);
      return;
    }

    if (this.pageCallbacks && this.pageCallbacks.onNavigate) {
      const contentPath = this.content.referenced.getContentPath();
      this.pageCallbacks.onNavigate(contentPath);
    }
  },
});
ContentView.extend(LinkContentView);

export default {
  name: 'link',
  view: LinkContentView,
};
