var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return " style=\"background-color: "
    + container.escapeExpression(((helper = (helper = helpers.colour1 || (depth0 != null ? depth0.colour1 : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"colour1","hash":{},"data":data,"loc":{"start":{"line":1,"column":65},"end":{"line":1,"column":76}}}) : helper)))
    + ";\"";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "  <img src=\""
    + alias4(((helper = (helper = helpers.logo || (depth0 != null ? depth0.logo : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"logo","hash":{},"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":20}}}) : helper)))
    + "\" style=\"vertical-align: baseline; height: 50px; object-fit: contain; padding: "
    + alias4(((helper = (helper = helpers.logoPadding || (depth0 != null ? depth0.logoPadding : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"logoPadding","hash":{},"data":data,"loc":{"start":{"line":3,"column":99},"end":{"line":3,"column":114}}}) : helper)))
    + ";\"></img>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "  <img src=\""
    + alias4(((helper = (helper = helpers.logo || (depth0 != null ? depth0.logo : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"logo","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":20}}}) : helper)))
    + "\" style=\"vertical-align: middle; height: 50px; object-fit: contain; padding: "
    + alias4(((helper = (helper = helpers.logoPadding || (depth0 != null ? depth0.logoPadding : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"logoPadding","hash":{},"data":data,"loc":{"start":{"line":5,"column":97},"end":{"line":5,"column":112}}}) : helper)))
    + ";\"></img><b>"
    + alias4(((helper = (helper = helpers.logoText || (depth0 != null ? depth0.logoText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"logoText","hash":{},"data":data,"loc":{"start":{"line":5,"column":124},"end":{"line":5,"column":136}}}) : helper)))
    + " </b>"
    + alias4(((helper = (helper = helpers.logoSecondaryText || (depth0 != null ? depth0.logoSecondaryText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"logoSecondaryText","hash":{},"data":data,"loc":{"start":{"line":5,"column":141},"end":{"line":5,"column":162}}}) : helper)))
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper;

  return " style=\"background-color: "
    + container.escapeExpression(((helper = (helper = helpers.colour2 || (depth0 != null ? depth0.colour2 : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"colour2","hash":{},"data":data,"loc":{"start":{"line":9,"column":96},"end":{"line":9,"column":107}}}) : helper)))
    + ";\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<a href=\"#\" class=\"logo\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.colour1 : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":24},"end":{"line":1,"column":85}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.logoOnly : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":6,"column":9}}})) != null ? stack1 : "")
    + "</a>\n\n<nav class=\"navbar navbar-static-top\" role=\"navigation\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.colour2 : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":55},"end":{"line":9,"column":116}}})) != null ? stack1 : "")
    + ">\n  <a href=\"#\" class=\"sidebar-toggle visible-xs-block\" data-toggle=\"offcanvas\" role=\"button\">\n    <span class=\"sr-only\">Toggle navigation</span>\n  </a>\n  <div class=\"navbar-custom-menu\">\n    <ul class=\"nav navbar-nav\">\n      <a id=\"logout-button\" href=\"#\" style=\"display: inline-block; color: white; padding: 15px\">Sign out</a>\n      <!--\n      <li class=\"dropdown user user-menu\">\n        <a href=\"#\" class=\"dropdown-toggle\" data-toggle=\"dropdown\">\n          <img src=\"img/aaron-160x160.jpg\" class=\"user-image\" alt=\"User Image\">\n          <span class=\"hidden-xs\">Aaron Cowie</span>\n        </a>\n        <ul class=\"dropdown-menu\">\n          <li class=\"user-header\">\n            <img src=\"img/aaron-160x160.jpg\" class=\"img-circle\" alt=\"User Image\">\n            <p>\n              Aaron Cowie - Senior Software Developer\n            </p>\n          </li>\n          <li class=\"user-footer\">\n            <div class=\"pull-left\">\n              <a href=\"#\" class=\"btn btn-default btn-flat\">Profile</a>\n            </div>\n            <div class=\"pull-right\">\n              <a href=\"#\" class=\"btn btn-default btn-flat\">Sign out</a>\n            </div>\n          </li>\n        </ul>\n      </li>-->\n    </ul>\n  </div>\n</nav>\n";
},"useData":true});