define([], function(){
  return function(url) {
    if((typeof url)=="string"){
      return url.split("/").pop().replace(/^[^_]+_/, "");
    }else{
      console.error(url);
      return url;
    }
  };
});
