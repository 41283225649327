import * as Sentry from "@sentry/browser";

import "@babel/polyfill";
import $ from 'jquery';
import Backbone from 'backbone';

import Project from './project';

//__webpack_public_path__ = window.location.pathname.split('/')[1];

Sentry.init({
  dsn: 'https://6c06ce8255fa4faa94b295055ed44479@o34536.ingest.sentry.io/76465',
  integrations: [],
  release: '0.1.0',
  environment: process.env.NODE_ENV,
});

function notifyUser() {
  if (typeof $ === 'undefined') {
    alert('Something went wrong, the Cruiser support team have been notified.');
  } else {
    $('#errordialog').modal({
      backdrop: 'static',
      keyboard: false,
    });
  }
}

const chainedErrorHandler = (original) => (...args) => {
  if (original) original(...args);
  notifyUser();
};


window.onerror = chainedErrorHandler(window.onerror);
window.onunhandledrejection = chainedErrorHandler(
  window.onunhandledrejection
);



document.body.style = '';

import { mountSpaLoginPage } from './spaLoginPage';



import is from 'is';
import fetchJSON from 'utils/fetchjson';
import FrontendClassLoader from './utils/frontendclassloader';

import Router from 'router';
import AppView from 'views/app';
import IndexView from 'views/index';
import DataModelView from 'views/datamodel';
import ContentPageView from 'views/contentpage';

import { detect } from 'detect-browser';
import { getSkinInfo } from './skin';

// handle the case where we don't detect the browser
const browser = detect();
switch (browser && browser.name) {
  case 'ie':
    alert('Internet Explorer is not supported. Please use Chrome, Firefox or Edge instead.');
    throw new Error('Internet Explorer is not supported. Please use Chrome, Firefox or Edge instead.');
    break;
}

class App {
  constructor() {
    this.view = null;
    this.router = null;
    this.currentView = null;

    this.inactivityTask = undefined;
    this.setupIdleTimeout();

    this.classLoader = new FrontendClassLoader();
    console.log('Content Types Loaded.');

    this.reload()
      .then(() => {
        this.router = new Router({ app: this });
        Backbone.history.start(/* { pushState: true} */);
      });
  }

  async reload(projectId) {
    const [skinInfo, clientData] = await Promise.all([getSkinInfo(), fetchJSON('api/client')]);
    this.skinInfo = skinInfo;
    
    this.clientData = clientData;
    // console.log("Client Data: ", this.clientData);

    if (clientData.projects.length === 0) {
      $('.content-header>h1').text('No projects found.');
      $('.content-header').append('<p><a href="logout">Sign out</a></p>');
      return;
    }    

    let pid = clientData.projects[0].id;
    if (projectId !== undefined) {
      pid = projectId;
    }

    return this.changeProject(pid);
  }

  async reloadAndNavigate(projectId, contentPath) {
    await this.reload(projectId);
    Backbone.history.navigate(`/projects/${projectId}/content/${contentPath}`);
    this.changeToContentPage(projectId, contentPath);
  }
  
  refresh() {
    window.location.reload();
  }

  resetTimer() {
    clearTimeout(this.inactivityTask);
    this.inactivityTask = setTimeout(this.refresh, 10*60*1000);
  }

  disableTimer() {
    clearTimeout(this.inactivityTask);
  }

  enableTimer() {
    clearTimeout(this.inactivityTask);
    this.inactivityTask = setTimeout(this.refresh, 10*60*1000);
  }
  
  // WORKAROUND/HACK (from https://stackoverflow.com/questions/667555/detecting-idle-time-in-javascript-elegantly)
  // Force reload page if idle (to prevent leaving window open and then trampling someone else's changes)
  setupIdleTimeout() {
    this.resetTimer();
    var events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
    const _this = this;
    events.forEach(function(name) {
      document.addEventListener(name, _this.resetTimer, true); 
    });
  }

  //From https://stackoverflow.com/questions/1144805/scroll-to-the-top-of-the-page-using-javascript-jquery
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  async changeProject(projectId) {
    this.currentProjectId = projectId;
    const [projectInfoJSON, dataModelJSON, contentJSONResponse] = await Promise.all([
      fetchJSON(`api/projects/${projectId}/info`),
      fetchJSON(`api/projects/${projectId}/datamodel`),
      fetchJSON(`api/projects/${projectId}/content`),
    ]);
    
    const { revision, data: contentJSON } = contentJSONResponse;
    this.contentRevision = revision;

    if (!is.object(dataModelJSON.main)) {
      console.error('main missing from data model'); return;
    }

    // this.contentJSON = contentJSON;
    // console.log("ContentJSON: ", contentJSON);

    // this.dataModelJSON = dataModelJSON;
    // console.log("DataModelJSON: ", dataModelJSON);

    this.project = new Project(projectId, projectInfoJSON, dataModelJSON, contentJSON, this.classLoader);

    if (this.view) this.view.remove();
    this.view = new AppView({
      clientData: this.clientData,
      projectId: projectId,
      dataModel: this.project.dataModel,
      content: this.project.content,
      skin: this.skinInfo
    });

    this.view.render();
  }

  requestPageChange() {
    if (this.currentView && this.currentView.requestPageChange) {
      return this.currentView.requestPageChange();
    }
    return Promise.resolve(true);
  }

  changePage(view) {
    this.scrollToTop();
    
    if (this.currentView !== null) {
      this.currentView.remove();
      this.currentView = null;
    }
    this.currentView = view;
  }

  tryChangeToContentPage(projectId, contentPath) {
    this.requestPageChange().then((allowed) => {
      if (allowed) {
        Backbone.history.navigate(`/projects/${projectId}/content/${contentPath}`);
        this.changeToContentPage(projectId, contentPath);
      }
      // else cancel was pressed, do nothing
    });
  }

  changeToContentPage(projectId, contentPath) {
    const changeThePage = () => {
      const contentPageView = new ContentPageView({
        content: this.project.content,
        path: contentPath,
        app: this,
        classLoader: this.classLoader,
        project: this.getCurrentProject(),
      });
      this.changePage(contentPageView);
      $('.content-wrapper').html(contentPageView.render().el);
    };

    if (projectId !== this.currentProjectId) {
      this.changeProject(projectId).then(changeThePage);
    } else {
      changeThePage();
    }
  }

  getCurrentProject() {
    let project = null;
    this.clientData.projects.forEach((p) => {
      if (p.id === this.currentProjectId) { project = p; }
    });
    return project;
  }

  changeToDataModelPage(projectId) {
    const changeThePage = () => {
      const dataModelView = new DataModelView({
        app: this,
        classLoader: this.classLoader,
        dataModel: this.dataModel,
        project: this.getCurrentProject(),
      });
      this.changePage(dataModelView);
      $('.content-wrapper').html(dataModelView.render().el);
    };

    if (projectId !== this.currentProjectId) {
      this.changeProject(projectId).then(changeThePage);
    } else {
      changeThePage();
    }
  }
}



(async () => {
  const clientData = await fetchJSON(`api/client`, [200, 401]);
  if ('type' in clientData && clientData['type'] === "error") {
    /* const unmount = */ await mountSpaLoginPage();
    return;
  }
  window.app = new App();
})();

