import $ from 'jquery';
import viewTemplate from 'templates/content/select_view.handlebars';
import editTemplate from 'templates/content/select_edit.handlebars';
import ContentView from '../content';

// Backbone view
const SelectContentView = ContentView.extend({
  events: {
    'change select': 'onSelectChange',
  },
  initialize(options) {
    ContentView.prototype.initialize.call(this, {
      app: options.app, content: options.content, parent: options.parent, viewTemplate, editTemplate, previewTemplate: null, pageCallbacks: options.pageCallbacks
    });
  },
  onSelectChange(e) {
    this.change($(e.currentTarget).val());
  },
  renderEdit() {
    const options = JSON.parse(JSON.stringify(this.content.contentType.options)); // deep copy via JSON
    for (const option of options) {
      if (
        (this.newValue && this.newValue == option.value)
            || (this.content.value == option.value)
      ) {
        option.selected = true;
      }
    }
    this.$el.html(this.editTemplate({ id: this.getDOMId(), value: this.newValue || this.content.value, options }));
  },
  renderView() {
    const options = JSON.parse(JSON.stringify(this.content.contentType.options)); // deep copy via JSON
    let name;
    for (const option of this.content.contentType.options) {
      if (this.content.value == option.value) {
        name = option.name;
      }
    }
    this.$el.html(this.viewTemplate({ id: this.getDOMId(), value: name || this.content.value, options }));
  },
});
ContentView.extend(SelectContentView);

export default {
  name: 'select-one',
  view: SelectContentView,
};
