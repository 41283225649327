var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "<a class=\"syncbutton\" href>[sync]</a>";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<a class=\"breadcrumb-link\" href=\"#/projects/"
    + alias4(((helper = (helper = helpers.projectId || (depth0 != null ? depth0.projectId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"projectId","hash":{},"data":data,"loc":{"start":{"line":18,"column":93},"end":{"line":18,"column":106}}}) : helper)))
    + "/content"
    + alias4(((helper = (helper = helpers.path || (depth0 != null ? depth0.path : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":18,"column":114},"end":{"line":18,"column":122}}}) : helper)))
    + "\">"
    + ((stack1 = ((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":18,"column":124},"end":{"line":18,"column":134}}}) : helper))) != null ? stack1 : "")
    + "</a>&ensp;|&ensp;";
},"5":function(container,depth0,helpers,partials,data) {
    return "<button type=\"button\" class=\"btn syncbutton\" autocomplete=\"off\">Sync to Web</button>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<section class=\"content-header\">\n  <h1>\n    View Content\n    <small class=\"title-buttons\">\n      <a class=\"editbutton\" href>[edit]</a>\n      "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isAppFlow : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":6},"end":{"line":6,"column":67}}})) != null ? stack1 : "")
    + "\n    </small>\n  </h1>\n  <ol class=\"breadcrumb\">\n    <li><a href=\"#\"><i class=\"fa fa-home\"></i> Home</a></li>\n    <li><a href=\"#/projects/"
    + alias4(((helper = (helper = helpers.projectId || (depth0 != null ? depth0.projectId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"projectId","hash":{},"data":data,"loc":{"start":{"line":11,"column":28},"end":{"line":11,"column":41}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.projectName || (depth0 != null ? depth0.projectName : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"projectName","hash":{},"data":data,"loc":{"start":{"line":11,"column":43},"end":{"line":11,"column":58}}}) : helper)))
    + "</a></li>\n    <li class=\"active\">Content</li>\n  </ol>\n</section>\n<section class=\"content\">\n  <div class=\"box box-default\">\n    <div class=\"box-header with-border\" style=\"color: #d2d6de;\">\n      <h3 class=\"box-title\">"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.breadcrumbs : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":28},"end":{"line":18,"column":160}}})) != null ? stack1 : "")
    + "</h3>\n    </div>\n    <form class=\"form-horizontal\">\n      <div class=\"box-body placecontenthere\">\n      </div>\n      <div class=\"box-footer\">\n        "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isAppFlow : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":8},"end":{"line":24,"column":116}}})) != null ? stack1 : "")
    + "\n        <button type=\"button\" class=\"btn btn-primary pull-right editbutton\">Edit</button>\n      </div>\n    </form>\n  </div>\n</section>\n";
},"useData":true});