import $ from 'jquery';
import Backbone from 'backbone';
import sideBarTemplate from 'templates/sidebar.handlebars';

const SideBarView = Backbone.View.extend({
  el: $('.main-sidebar'),
  template: sideBarTemplate,
  initialize(options) {
    this.selectedProject = options.projectId;
    const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });
    this.projects = options.projects.sort((a, b) => collator.compare(a.name, b.name));
    if (this.projects.length > 0) {
      this.projects[0].first = true; // hack to open first tree menu
    }
    for (const project of this.projects) {
      project.selected = (project.id === this.selectedProject);
    }
  },
  sidebarShown: false,
  toggleSidebar(){
    if (this.sidebarShown) {
      this.$el[0].style = "";
      this.sidebarShown = false;
    } else {
      this.$el[0].style="transform: none";
      this.sidebarShown = true;
    }
  },
  render() {
    this.$el.html(this.template({ projects: this.projects }));
    $('.sidebar-toggle').on("click", this.toggleSidebar.bind(this));
    return this;
  },
});

export default SideBarView;
