var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <button type=\"button\" class=\"btn btn-default discardbutton\" data-dismiss=\"modal\">Discard Changes</button>\n        <button type=\"button\" class=\"btn btn-primary savebutton\" data-dismiss=\"modal\">Save Changes</button>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <button type=\"button\" class=\"btn btn-primary discardbutton\" data-dismiss=\"modal\">Discard Changes</button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div id=\"confirmdialog\" class=\"modal fade\" tabindex=\"-1\" role=\"dialog\">\n  <div class=\"modal-dialog\">\n    <div class=\"modal-content\">\n      <div class=\"modal-header\">\n        <h4 class=\"modal-title\">Unsaved Changes</h4>\n      </div>\n      <div class=\"modal-body\">\n        <p>You have unsaved changes on this page.</p>\n      </div>\n      <div class=\"modal-footer\">\n        <button type=\"button\" class=\"btn btn-default cancelbutton\" data-dismiss=\"modal\">Cancel</button>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.showSave : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":17,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});