import $ from 'jquery';
import Backbone from 'backbone';
import confirmDialogTemplate from 'templates/confirmdialog.handlebars';

const ConfigDialogView = Backbone.View.extend({
  template: confirmDialogTemplate,

  events: {
    'click .cancelbutton': 'onCancel',
    'click .discardbutton': 'onDiscard',
    'click .savebutton': 'onSave'
  },

  initialize(options) {
    this.saveCallback = options.onSave;
    this.showSave = options.showSave;
  },

  async show() {
    $('.modal-wrapper').append(this.render().el);
    this.modal = $('#confirmdialog');
    this.modal.modal({ backdrop: 'static' });
    /* this.modal.on("hide.bs.modal", function(e){
      reject();
    }); */
    const allowed = await new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
    this.modal.modal('hide');
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
    return allowed;
  },

  render() {
    this.$el.html(this.template({ showSave: this.showSave }));
    return this;
  },

  onCancel(e) {
    // e.preventDefault();
    this.resolve(false);
  },

  onDiscard(e) {
    // e.preventDefault();
    this.resolve(true);
  },

  async onSave(e) {
    await this.saveCallback();
    this.resolve(true);
  }

});

export default ConfigDialogView;
