import object from 'views/content/object';

const ObjectContentView = object.view;

// Backbone view
const ModelContentView = ObjectContentView.extend({
  initialize(options) {
    ObjectContentView.prototype.initialize.call(this, options);
  },
});
ObjectContentView.extend(ModelContentView);

export default {
  name: 'model',
  view: ModelContentView,
};
