import $ from 'jquery';
import viewTemplate from 'templates/content/long_text_view.handlebars';
import editTemplate from 'templates/content/long_text_edit.handlebars';
import ContentView from '../content';

// Backbone view
const LongTextContentView = ContentView.extend({
  events: {
    'change textarea': 'onTextChange',
  },
  initialize(options) {
    ContentView.prototype.initialize.call(this, {
      app: options.app, content: options.content, parent: options.parent, viewTemplate, editTemplate, previewTemplate: null, pageCallbacks: options.pageCallbacks
    });
  },
  onTextChange(e) {
    this.change($(e.currentTarget).val());
  },
});
ContentView.extend(LongTextContentView);

export default {
  name: 'long-text',
  view: LongTextContentView,
};
