import $ from 'jquery';
import Backbone from 'backbone';
import contentPageViewTemplate from 'templates/content-page-view.handlebars';
import contentPageEditTemplate from 'templates/content-page-edit.handlebars';
import ConfirmDialogView from 'views/confirmdialog';

/** @type {Set<string>} */
const syncIsRunning = new Set();

const ContentPageView = Backbone.View.extend({
  viewTemplate: contentPageViewTemplate,
  editTemplate: contentPageEditTemplate,
  events: {
    'click .editbutton': 'onClickEdit',
    'click .syncbutton': 'onClickSync',
    'click .cancelbutton': 'onClickCancel',
    'click .savebutton': 'onSave',
    'click .breadcrumb-link': 'onClickBreadcrumb',
  },
  initialize(options) {
    this.classLoader = options.classLoader;
    this.app = options.app;
    this.content = options.content;
    this.project = options.project;
    this.changePath(options.path || ''); // sets currentContent and currentContentView
    this.editMode = false;
  },
  changePath(path) {
    this.path = path;
    this.currentContent = this.getSubContent(this.path);
    if (this.currentContentView) this.currentContentView.remove();
    const viewClass = this.classLoader.getView(this.currentContent.name);
    this.currentContentView = new viewClass({ 
      app: this.app, 
      content: this.currentContent, 
      parent: null,
      pageCallbacks: {
        shouldAllowSelection: view => false,
        onSelect: view => {},
        onNavigate: path => {
          this.app.tryChangeToContentPage(this.project.id, path.substring(1));
        }
      }
    });
    this.currentContentView.loadChildViews();
    this.currentContentView.setEditMode(this.editMode);
    this.app.scrollToTop();
  },
  validate() {
    return this.currentContentView.childViews.every((obj) => {
      const view = obj.view;
      return view.validate();
    });
  },
  async requestPageChange(showSave = true) {
    const changedViews = this.currentContentView.getChangedViews();
    console.warn(changedViews);
    if (changedViews.length > 0) {
      const confirmDialogView = new ConfirmDialogView({onSave: this.save.bind(this), showSave: showSave});
      const allowed = await confirmDialogView.show();
      confirmDialogView.remove();
      return allowed;
    } else {
      return true;
    }
  },
  edit(e) {
    this.editMode = true;
    this.app.disableTimer();
    this.currentContentView.setEditMode(this.editMode);
    this.render();
  },
  cancel(e) {
    this.editMode = false;
    this.app.enableTimer();
    this.currentContentView.setEditMode(this.editMode);
    this.currentContentView.clear();
    this.app.reloadAndNavigate(this.app.currentProjectId, this.currentContentView.getContentPath().substring(1));
  },
  async save() {
    if (!this.validate()) {
      //throw new Error('Validation failed.');
      return;
    }

    this.app.enableTimer();
    
    const changedViews = this.currentContentView.getChangedViews();
    console.info(changedViews);
    if (changedViews.length > 0) {
      // Grey out screen
      const pleaseWaitDiv = $('<div class="modal hide" id="pleaseWaitDialog" data-backdrop="static" data-keyboard="false"><div class="modal-header"><h1>Processing...</h1></div><div class="modal-body"><div class="progress progress-striped active"><div class="bar" style="width: 100%;"></div></div></div></div>');
      pleaseWaitDiv.modal();

      // Call save functions and once they are all complete, continue
      const changedContent = changedViews.map(view => view.getChanges());
      const contentSavePromises = changedViews.map((view) => {
        if (view.changed) {
          return view.save();
        }
        return Promise.resolve();
      });
      await Promise.all(contentSavePromises);

      const jsonObj = this.content.toJSON();
      const jsonData = JSON.stringify({ new: jsonObj, changed: changedContent, revision: this.app.contentRevision }, null, 2);
      // console.log("OLD:");
      // console.warn(JSON.stringify(app.contentJSON, null, 2));
      // console.log("NEW:");
      console.warn(jsonData);

      const response = await fetch(`api/projects/${this.app.getCurrentProject().id}/save`, {
        method: 'POST',
        credentials: 'same-origin',
        body: jsonData,
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      });

      if (response.status !== 200) {
        throw new Error(`Looks like there was a problem. Failed to save. Status Code: ${response.status}`);
        // `Server error when fetching JSON from ${path }. Error: ${JSON.stringify(data, null, 2)}`
      }
      
      const data = await response.json();
      if (data.status === 'error' && data.type === 'version_mismatch') {
        alert(data.description);
        await this.app.reloadAndNavigate(this.app.currentProjectId, this.currentContentView.getContentPath().substring(1));
        pleaseWaitDiv.modal('hide');
      } else if (data.status === 'error') {
        throw new Error(`Failed to save${JSON.stringify(data, null, '  ')}`);
      } else {
        console.log('Saved successfully.');
        await this.app.reloadAndNavigate(this.app.currentProjectId, this.currentContentView.getContentPath().substring(1));
        pleaseWaitDiv.modal('hide');
      } // reload page
    } else {
      this.editMode = false;
      this.currentContentView.setEditMode(this.editMode);
      this.render();
    }
  },
  onSave(e){
    e.preventDefault();
    return this.save();
  },
  getSubContent(path) {
    if (path !== '') {
      const elements = path.split('/');
      let current = this.content;
      for (const val of elements) {
        if (val === '') continue;
        if (current.getChild) {
          const child = current.getChild(val);
          if (child) current = child;
          else throw new Error(`Specified content path was not found: ${path}.\r\n Failed at: ${val}`);
        } else {
          throw new Error(`Specified content path was not found: ${path}.\r\n Failed at: ${val}`);
        }
      }
      return current;
    }
    return this.content;
  },
  onClickEdit(e) {
    e.preventDefault();
    this.requestPageChange().then((allowed) => {
      if (allowed) this.edit();
    });
  },
  async onClickSync(e) {
    e.preventDefault();
    const projectId = this.project.id;
    if (syncIsRunning.has(projectId)) {
      alert('Sync for this project is still running; please try again in a few minutes.');
    } else {
      syncIsRunning.add(projectId);
      const [syncAnchor, syncButton] = document.getElementsByClassName('syncbutton');
      syncAnchor.style.display = 'none';
      // NOTE: Firefox caches the `disabled` state across reloads.
      // To disable this, make sure the button has autocomplete=off (even though
      // autocomplete is not supposed to apply to buttons).
      // https://bugzilla.mozilla.org/show_bug.cgi?id=654072
      syncButton.disabled = true;
      syncButton.textContent = '⏳ Syncing…';
      const done = () => {
        syncIsRunning.delete(projectId);
        syncAnchor.style.display = '';
        syncButton.disabled = false;
        syncButton.textContent = 'Sync to Web';
      };
      const response = await fetch(`api/projects/${projectId}/sync`, {
        method: 'POST',
        credentials: 'same-origin',
      });
      if (response.status === 504) {
        // If the call times out, check for completion every 5 seconds.
        // In this case, we don't know if the call succeeded, only that it
        // completed (or crashed).
        const syncCheck = async () => {
          const checkResponse = await fetch(`api/projects/${projectId}/sync-check`, {
            credentials: 'same-origin',
          });
          if (checkResponse.status === 200) {
            const data = await checkResponse.json();
            if (data.status === 'success') {
              if (data.result) {
                window.setTimeout(syncCheck, 5000);
              } else {
                done();
              }
              return;
            }
          }
          console.error(`Failed checking sync status for project ${this.project.name} (${projectId})`);
        };
        window.setTimeout(syncCheck, 5000);
      } else {
        done();
        if (response.status === 200) {
          const data = await response.json();
          if (data.status === 'success') return;
        }
        throw new Error(`Failed to sync project ${this.project.name} (${projectId})`);
      }
    }
  },
  onClickCancel(e) {
    e.preventDefault();
    this.requestPageChange(false).then((allowed) => {
      if (allowed) this.cancel();
    });
  },
  onClickBreadcrumb(e) {
    e.preventDefault();
    const projectId = $(e.currentTarget).attr('href').replace(/^#\/?projects\//, '').replace(/\/content\/?.*$/, '');
    let contentPath = $(e.currentTarget).attr('href').replace(/^#\/?projects\/[^/]+\/content\/?/, '');
    if (!contentPath) contentPath = '';
    this.app.tryChangeToContentPage(projectId, contentPath);
  },
  getBreadcrumbs() {
    const breadcrumbs = [{ name: '<i class="fa fa-home"></i>&nbsp;&nbsp;Home', path: '', projectId: this.app.currentProjectId }];
    const parentChain = this.currentContent.getParentChain();
    parentChain.shift();
    parentChain.forEach((content) => {
      breadcrumbs.push({ name: content.getDisplayName(), path: content.getContentPath(), projectId: this.app.currentProjectId });
    });
    return breadcrumbs;
  },
  render() {
    if (this.editMode) {
      this.$el.html(this.editTemplate({ breadcrumbs: this.getBreadcrumbs(), projectId: this.project.id, projectName: this.project.name }));
    } else {
      this.$el.html(this.viewTemplate({ breadcrumbs: this.getBreadcrumbs(), isAppFlow: this.project.isAppFlow, projectId: this.project.id, projectName: this.project.name }));
    }
    this.$el
      .find('.placecontenthere')
      .removeClass('placecontenthere')
      .html(this.currentContentView.render().el);
    return this;
  },
});

export default ContentPageView;
